<template>
    <div>
        <div id="real-table"></div>
    </div>
</template>

<script>
import axios from 'axios';
import config from '@/config.js';

var tabledata = [];

export default{
    name: 'List',
    data: () => ({
    }),
    created(){
        let nav_arry_str=[];
        //檢查會員權限
        const apiurl_authMem = config.geturl(config.API.exeAuthMem,{});
        const memlogin_url = config.geturl(config.API.memLogin,{});
        axios.get(apiurl_authMem).then(response => {
            //console.log(response.data);
            if(response.data.isOk){
                let nav_str_ay = response.data.log.split(',');
                nav_str_ay.forEach(function(item){
                    nav_arry_str.push(parseInt(item));
                });
                if(!nav_arry_str.includes(7)){
                    alert("您沒有該頁權限，請重新登入");
                    location.href=memlogin_url;
                }
            }else{
                alert("您沒有該頁權限，請重新登入");
                location.href=memlogin_url;
            }
        });
    },
    mounted(){
        $(function() {
            $(".nav_mainTitle").text("即時數據");

            const apiurl=config.geturl(config.API.realtime,{limit:1000});
            axios.get(apiurl).then(response => {
                if(response.data !== null && response.data !== ""){
                    tabledata = response.data;

                    var narry=[];
                    $.each(tabledata,function(index,item){
                        var wind_str = (item.wd != null && item.wd != "")?windDirection(item.wd,true):"";
                        narry.push({
                            id:item.id,
                            device:item.device,
                            lat:item.lat,
                            lon:item.lon,
                            pm10:item.pm10,
                            pm25:item.pm25,
                            temp:item.temp,
                            hum:item.hum,
                            co:item.co,
                            co2:item.co2,
                            tvoc:item.tvoc,
                            no2:item.no2,
                            o3:item.o3,
                            nh3:item.nh3,
                            h2s:item.h2s,
                            ws:item.ws,
                            wd:wind_str,
                            time: formatDatetime(item.time),
                        });
                    });

                    //console.log(tabledata);
                    var table = new Tabulator("#real-table", {
                        height:"auto",
                        locale:"en-gb",
                        langs:{
                            "en-gb":{
                                "pagination":{
                                    "page_size":"頁數", //label for the page size select element
                                    "first":"第一頁", //text for the first page button
                                    "first_title":"第一頁", //tooltip text for the first page button
                                    "last":"最後一頁",
                                    "last_title":"最後一頁",
                                    "prev":"上一頁",
                                    "prev_title":"上一頁",
                                    "next":"下一頁",
                                    "next_title":"下一頁",

                                },
                            }
                        },
                        data:narry,           //load row data from array
                        layout:"fitColumns",      //fit columns to width of table
                        //responsiveLayout:"hide",  //hide columns that dont fit on the table
                        tooltips:true,            //show tool tips on cells
                        addRowPos:"top",          //when adding a new row, add it to the top of the table
                        history:true,             //allow undo and redo actions on the table
                        pagination:"local",       //paginate the data
                        paginationSize:50,         //allow 7 rows per page of data
                        paginationSizeSelector:true,
                        //paginationCounter:"rows", //display count of paginated rows in footer
                        movableColumns:false,      //allow column order to be changed
                        resizableRows:false,       //allow row order to be changed
                        resizableColumnFit:true,
                        columns:[
                            {title:"編號", field:"id", width:130, sorter:"string", headerFilter:true, headerFilterPlaceholder:"篩選編號"},
                            {title:"測站名稱", field:"device", width:130, sorter:"string"},
                            {title:"經度", field:"lat", width:150, sorter:"string"},
                            {title:"緯度", field:"lon", width:150, sorter:"string"},
                            {title:"PM10(µg/m3)", field:"pm10", width:130, sorter:"string"},
                            {title:"PM2.5(µg/m3)", field:"pm25", width:130, sorter:"string"},
                            {title:"溫度(°C)", field:"temp", width:80, sorter:"string"},
                            {title:"濕度(%)", field:"hum", width:80, sorter:"string"},
                            {title:"CO(ppm)", field:"co", width:80, sorter:"string"},
                            {title:"CO2(ppm)", field:"co2", width:80, sorter:"string"},
                            {title:"VOC(ppm)", field:"tvoc", width:80, sorter:"string"},
                            {title:"NO2(ppb)", field:"no2", width:80, sorter:"string"},
                            {title:"O3(ppb)", field:"o3", width:80, sorter:"string"},
                            {title:"NH3(ppb)", field:"nh3", width:80, sorter:"string"},
                            {title:"H2S(ppb)", field:"h2s", width:80, sorter:"string"},
                            {title:"風速", field:"ws", width:70, sorter:"string"},
                            {title:"風向", field:"wd", width:70, sorter:"string"},
                            {title:"時間", field:"time", width:170, sorter:"string"},
                        ],
                        
                        //autoColumns:true,
                    });
                }

            });
            


        })
    }
}
</script>